import { z } from 'zod'

import {
  APIProductOption,
  APIProductParameter,
  APIStandard,
  APIStandardGroup,
  fetchDeviceTypes,
  fetchProducts,
} from '../../backend'
import { MeasurLocale } from '../locale/i18n'

export type LocalisedData = Partial<Record<KebabSnake<MeasurLocale>, string>>

export const IndustryOrMaterial = z.enum([
  'biorefinery',
  'building-materials',
  'chemical',
  'child-care',
  'cosmetic',
  'energy-and-fuel',
  'environmental',
  'food-and-feed',
  'food-contact',
  'marine',
  'medical',
  'metal',
  'packaging',
  'paper',
  'pharma',
  'polymer',
  'safety-equipment',
  'semiconductors',
  'textile',
  'waste',
])
export type IndustryOrMaterial = z.infer<typeof IndustryOrMaterial>

export const TypeOfTesting = z.enum([
  'air-quality',
  'biology',
  'compositional',
  'electrical',
  'elemental',
  'fire',
  'mechanical',
  'microbiology',
  'microscopy',
  'particle',
  'properties',
  'regulatory',
  'rheology',
  'std-product',
  'surface',
  'thermal',
])
export type TypeOfTesting = z.infer<typeof TypeOfTesting>

export const QualitySystem = z.enum([
  'accreditedMethod',
  'accreditedLab',
  'gmp',
  'glp',
  '9001',
  'reach',
  'fda',
])
export type QualitySystem = z.infer<typeof QualitySystem>

export const ProductScopeType = z.enum(['per-sample', 'first-sample'])
export type ProductScopeType = z.infer<typeof ProductScopeType>

// Parameter slugs, that have special handling and require link with their label
// Add localisations for each enum value
export enum LinkParameterSlug {
  DANGEROUS_GOODS = 'dangerous-goods',
}

export type ProductOption = FlattenTranslations<APIProductOption, MeasurLocale>

export type ProductParameter = FlattenTranslations<
  APIProductParameter,
  MeasurLocale
>

export const AccreditationStatus = z.enum([
  'accreditedMethod',
  'accreditedLab',
  'unaccredited',
])
export type AccreditationStatus = z.infer<typeof AccreditationStatus>

export type Standard = APIStandard & { slug: string }
export type StandardGroup = APIStandardGroup

export type DeviceType = FlattenTranslations<
  Awaited<ReturnType<typeof fetchDeviceTypes>>[number],
  MeasurLocale
>

export type Product = FlattenTranslations<
  Omit<
    Awaited<ReturnType<typeof fetchProducts>>[number],
    'standards' | 'deviceTypes'
  >,
  MeasurLocale
> & {
  owner: { email: string; name: string }
  deviceTypes: DeviceType[]
  standards: Standard[]
}

export const industryOrMaterialValues = IndustryOrMaterial.options
export const typeOfTestingValues = TypeOfTesting.options
