import {
  IndustryOrMaterial,
  LinkParameterSlug,
  QualitySystem,
  TypeOfTesting,
} from '../../logic/catalog'
import { territoriesEn } from '../../logic/country'
import { ProductFilterType } from '../../logic/shared-types'
import { StaticPage } from '../../logic/staticPage'

export default {
  aboutUs: {
    partnerQuestion: 'Want to join our partner network?',
  },
  address: {
    openingHours: {
      helsinki: 'Mon–Fri 7:45–15:45',
      sampleReception: 'Sample reception',
    },
  },
  aria: {
    linkedInPage: 'LinkedIn page',
    search: 'Search',
  },
  common: {
    askForOffer: 'Ask for an offer',
    askMore: 'Ask more',
    cancel: 'Cancel',
    contactUs: 'Contact us',
    createdAt: 'Created',
    edit: 'Edit',
    formSubmitError:
      'Failed to submit the form. Please contact us through another channel.',
    getStarted: 'Get started today',
    goToBlog: 'Read our blog',
    goToShop: 'See our services',
    goToSolutions: 'See our solutions',
    homePage: 'Home page',
    inactiveEmployee: 'inactive',
    keepReading: 'Keep reading',
    optional: '(optional)',
    orContact: 'or contact us at',
    partner: 'Become a partner',
    readMore: 'Learn More',
    sampleSingular: 'sample',
    samplesplural: 'samples',
    save: 'Save',
  },

  banner: {
    one: {
      primary: 'Leave your tests to us',
      secondary: 'and focus on what you do best',
    },
    two: {
      primary: 'Your partner in all your',
      secondary: 'measurement and testing needs.',
    },
  },
  blogLeadForm: {
    ctaButton: 'Send',
    emailLabel: 'Your work email',
    error:
      'Failed to submit the form. Please contact us through another channel.',
    success: 'Thank you! Please check your email',
  },
  blogPostPage: {
    hero: {
      published: 'Published',
      updated: 'Updated',
    },
    tableOfContents: 'Table of contents',
  },
  campaign: {
    whyUs1: {
      long: 'get all your tests done in the same place.',
      short: 'Widest range of testing methods',
    },
    whyUs2: {
      long: 'for every test, we have sourced the best lab for that particular method, which means that you get the most accurate results and the best price.',
      short: 'Best laboratories',
    },
    whyUs3: {
      long: 'get help from method experts if you are unsure about standards, your testing needs or sample suitability.',
      short: 'Personal service',
    },
    whyUsTitle: 'Why choose Measurlabs?',
  },
  careers: {
    copyMessage: 'Copy link to the position',
    copySucces: 'Link copied to clipboard',
  },
  cart: {
    additionalDetails: {
      heading: 'Additional details',
      one: 'What are you looking to use the results for? Anything else we should know?',
      two: 'The better we understand your needs, the better testing service we can provide for you.',
    },
    additionalTests:
      'You can add additional testing services to the same order:',
    additionalTestsBtn: '+ Add more testing services',
    nextStep: 'Next: Billing',
    removeItem: 'Remove item from the analysis order',
    samplesHeading: 'Samples ({{count}})',
    serviceFee: 'Service fee',
    serviceFeeDescription:
      'The fee includes project management, reporting and sample handling, storage and disposal. It is charged once for every order (set of samples analyzed at the same time).',

    tests: {
      heading: 'Tests ({{count}})',
      turnaroundHeading: 'Estimated turnaround time',
      turnaroundWeeksPlural: '{{value}} weeks',
      turnaroundWeeksSingular: '{{value}} week',
    },

    sampleConfiguration: {
      hideRequirements: 'Hide sample requirements',
      showRequirements: 'Show sample requirements',
    },

    billing: {
      address: 'Billing address',
      addressPlaceholder: '13 Pariser Platz\n10117 Berlin',
      billingEmail: 'Billing email for PDF invoice',
      billingHeading: 'Billing information',
      contactDetailsHeading: 'Contact details',
      eInvoiceOperator: 'European e-invoicing operator ID',
      eInvoiceRoutingNumber:
        'European e-invoicing routing number (EDI/GLN/OVT)',
      nextStep: 'Next: Review order',
      paymentMethod: {
        bankTransfer: 'PDF invoice (bank transfer)',
        creditCard: 'Credit card (payment link sent by email)',
        eInvoice: 'E-invoice (Finnish customers only)',
        label: 'Your preferred payment method',
        notSelected: 'Invoice or credit card',
      },
      reference: 'Reference or Purchase Order number',
      referenceHelper:
        'If you have a reference or a purchase order number you’d like us to include on the invoice, please enter it here.',
      vatNumber: 'VAT number',
      vatNumberPlaceholder: 'FI28204611',
    },
    confirmation: {
      confirmationDetails:
        'We have successfully received your order. A confirmation has been sent to your email {{email}}.',
      confirmationEstimation:
        '{{b|We will review your order in approximately one business day.}} Meanwhile, you can prepare the samples for shipping.',
      serviceCoverage:
        'Measurlabs brings you the best laboratory testing services sourced from our global network of over 900 partner laboratories. We are based in Finland but routinely serve clients around the world.',
      shippingInstructionsLink:
        'Full instructions for sample shipment can be found on our {{link|shipping instructions page}}.',
    },
    copyCart: {
      copiedMessage: 'Link copied to clipboard',
      modal: {
        content:
          'You have opened a link to a pre-filled order. It seems that you already have an unfinished analysis order. Would you like to proceed with replacing the existing order contents?',
        heading: 'Pre-filled order',
        submitLabel: 'Replace current order',
      },
      prompt: 'Copy order',
    },
    navigation: {
      billingInformation: 'Billing information',
      cart: 'Tests and samples',
      review: 'Review order',
    },
    orderSummary: {
      paymentMethodLabel: 'Payment method:',
      priceLabel: 'Total price (VAT 0):',
      productCountPlural: 'tests',
      productCountSingular: 'test',
      sampleCountPlural: 'samples',
      sampleCountSingular: 'sample',
      sampleDeliveryLabel: 'Sample delivery:',
      serviceFee: 'Includes {{fee}} service fee',
      tatLabel: 'Estimated turnaround time:',
    },
    review: {
      acceptMarketing: 'I’d like to hear of special offers and service updates',
      acceptTerms:
        'I accept {{link|Measurlabs general terms and conditions of sales}}',
      additionalDetails: {
        heading: 'Additional details',
        notProvided: 'Not provided.',
      },
      errors: {
        apiError:
          'Failed to submit the order. Try again later or {{a|contact us!}}',
        termsNotAccepted: 'You need to accept our terms to submit the order.',
      },
      nextSteps: {
        content: `{{li|We will send you an automated order confirmation email in a few minutes. The email also contains the sample shipping instructions so you can prepare the samples for shipment.}}
          {{li|Our expert will review your order in approximately one business day to ensure the testing method is suitable for your needs and samples. We will send you an email once we have reviewed the order.}}
          {{li|You send us the samples. We will let you know by email when we have received the samples.}}
          {{li|For new customers we require advance payment. We will send you an invoice once we receive the samples. For returning customers, we'll send the invoice after the results have been reported.}}
          {{li|We will analyse the samples and send you our report with the results via email.}}
        `,
        contentCC: `{{li|We will send you an automated order confirmation email in a few minutes. The email also contains the sample shipping instructions so you can prepare the samples for shipment.}}
          {{li|Our expert will review your order in approximately one business day to ensure the testing method is suitable for your needs and samples. We will send you an email once we have reviewed the order.}}
          {{li|You send us the samples. We will let you know by email when we have received the samples.}}
          {{li|For new customers we require advance payment. We will send you the credit card payment details once we receive the samples. For returning customers, we'll send the payment instructions after the results have been reported.}}
          {{li|We will analyse the samples and send you our report with the results via email.}}
        `,
        heading: 'What happens when you submit the order',
      },
      pricingSummaryHeading: 'Pricing summary',
      sampleRequirements: {
        heading: 'Sample requirements',
        matricesHeading: 'Suitable sample matrices',
        minimumSampleAmountHeading: 'Required sample quantity',
      },
      sampleSummary: {
        fallbackName: 'Unnamed',
        heading: 'Sample summary',
        noMaterialInformation: 'No material provided',
        sampleCountPlural: '{{numSamples}} samples',
        sampleCountSingular: '{{numSamples}} sample',
        sampleNameHeading: 'Name',
        selected: 'Selected',
      },
      submitOrder: 'Submit order',
    },
  },
  categoryListing: {
    noResultsContact:
      "Can't find what you're looking for? {{link|Contact us}} and we'll help!",
  },
  contact: {
    logistics: {
      content:
        'Please reach out to {{email|info@measurlabs.com}} or call {{phone|+358 50 336 6128}}',
      title: 'Logistics, invoicing and general inquiries',
    },
    officeNames: {
      FI: 'Headquarters (Finland)',
      GB: 'United Kingdom',
    },
    offices: 'Office locations',
    salesAnchor: 'sales',
  },
  contactBanner: {
    businessHours:
      'Business hours: Mon–Fri 9 AM – 5 PM Finnish time (EST/EEST)',
    heading: "Questions? We're happy to help.",
    sales: 'Contact sales',
  },
  contactForm: {
    defaultInstructions: {
      1: 'How many samples do you have and what is the sample material?',
      2: 'Do you have a recurring need for these tests? If yes, how often and for how many samples at a time?',
      intro:
        'Answering the following questions helps us prepare an offer for you faster:',
    },
    heading: {
      partner: 'Become a partner laboratory',
      primary: 'Ask for an offer',
      secondary: 'Contact',
    },
    label: {
      company: 'Company',
      companySublabel:
        'We are currently only able to serve companies and other organizations.',
      email: 'Work email',
      message: 'Please describe your testing needs in detail',
      name: 'Name',
      phone: 'Work phone',
      phoneSublabel: 'Please use the international format (+1 123 555 1234).',
      services: 'What testing services do you offer?',
    },
    lead: {
      partner:
        'Fill in the form and we’ll be in touch. Alternatively, you can email us at {{email}} or call {{phone}}.',
      primary: "Fill in the form, and we'll reply in {{b|one business day}}.",
    },
    placeholder: {
      phone: '+1 123 555 1234',
    },
    questions:
      'Have questions or need help? Email us at {{email|info@measurlabs.com}} or call {{link|our sales team}}.',
    send: 'Send',
    sendErr: 'Your message was not sent. Please try again.',
    success: {
      content:
        'We have received your message and our experts will reply by the next business day.',
      heading: 'Thank you!',
    },
  },
  country: {
    ...territoriesEn,
    other: 'Other countries',
  },
  expert: {
    defaultHeading: 'Questions?',
    defaultIntro: '{{name}} is happy to answer all your questions.',
  },
  footer: {
    addressCountryFinland: 'Finland',
    businessHours: {
      fi: 'Mon–Fri 9 AM – 5 PM Finnish Time (EST/EEST)',
      gb: 'Mon–Fri 9 AM – 5 PM (GMT/BST)',
    },
    headings: {
      address: 'Visiting address',
      businessHours: 'Business hours',
      contact: 'Contact',
      courierContact: 'Recipient for courier deliveries',
      nav: 'Navigation',
      portal: 'Customer portal',
    },
    portalIntro:
      'All your projects, results, and more available in our customer portal.',
    socialLinkAriaLabel: {
      Facebook: `Measurlabs' Facebook page`,
      Instagram: `Measurlabs' Instagram page`,
      LinkedIn: `Measurlabs' LinkedIn page`,
      X: `Measurlabs' X page`,
    },
  },
  home: {
    expertCta: 'Get to know our experts',
    hero: {
      clientCount: '...and more than 700 other happy clients',
    },
  },
  industryOrMaterial: {
    biorefinery: 'Biorefinery',
    'building-materials': 'Building materials',
    chemical: 'Chemicals',
    'child-care': 'Children’s products',
    cosmetic: 'Cosmetics',
    'energy-and-fuel': 'Energy and fuels',
    environmental: 'Environmental testing',
    'food-and-feed': 'Food, feed and supplements',
    'food-contact': 'Food contact materials',
    marine: 'Marine',
    medical: 'Medical devices',
    metal: 'Metals and mining',
    packaging: 'Packaging',
    paper: 'Paper and board',
    pharma: 'Pharmaceuticals',
    polymer: 'Polymers and plastics',
    'safety-equipment': 'Safety equipment',
    semiconductors: 'Semiconductors',
    textile: 'Textiles and furniture',
    waste: 'Waste materials',
  } satisfies Record<IndustryOrMaterial, string>,
  localeSelector: {
    currency: 'Currency',
    language: 'Language',
  },
  metadata: {
    '404': {
      description:
        'The page you were looking for could not be found. It might have been removed or moved to another URL.',
      title: 'Page not found',
    },
    [StaticPage.CART]: {
      title: 'Tests and samples',
    },
    [StaticPage.CART_BILLING]: {
      title: 'Billing information',
    },
    [StaticPage.CART_REVIEW]: {
      title: 'Review order',
    },
    [StaticPage.PORTAL_OFFERS_ID]: {
      title: 'Offer',
    },
    [StaticPage.ORDER_CONFIRMATION]: {
      title: 'Thank you for your order!',
    },
    [StaticPage.PORTAL_ORDER_SUBMISSION_REVIEW]: {
      title: 'Review order',
    },
    [StaticPage.PORTAL_ORDER_SUBMISSION_CONTACT_DETAILS]: {
      title: 'Create an order - Contact details',
    },
    [StaticPage.PORTAL_ORDER_SUBMISSION_SAMPLES]: {
      title: 'Create an order - Tests and samples',
    },
    [StaticPage.PRIVACY]: {
      description: 'Measurlabs privacy policy',
      title: 'Privacy policy',
    },
    [StaticPage.SHIPPING_INSTRUCTIONS]: {
      description: 'Sample shipping instructions',
      title: 'Shipping instructions',
    },
    [StaticPage.TERMS_SALES]: {
      description: 'Measurlabs terms and conditions',
      title: 'Terms and conditions - Sales',
    },
    [StaticPage.TERMS_ONLINE]: {
      description: 'Measurlabs terms and conditions',
      title: 'Terms and conditions - Website',
    },
    [StaticPage.PRODUCTS]: {
      description:
        'Laboratory analyses and testing services offered by Measurlabs.',
      title: 'Testing services',
    },
    [StaticPage.PROJECTS]: {
      title: 'Projects',
    },
    offersAndOrders: {
      title: 'Offers and orders',
    },
  },
  methodPage: {
    headings: {
      faq: 'Frequently asked questions',
      sampleMatrices: 'Suitable sample matrices',
    },
  },
  nav: {
    [StaticPage.INDEX]: 'Home',
    [StaticPage.METHODS]: 'Methods',
    [StaticPage.PARTNERS]: 'For labs',
    [StaticPage.SOLUTIONS]: 'Solutions',
    [StaticPage.PRODUCTS]: 'Testing services',
    [StaticPage.CONTACT]: 'Contact',
    [StaticPage.ABOUT]: 'About',
    [StaticPage.CAREERS]: 'Careers',
    [StaticPage.PRIVACY]: 'Privacy',
    [StaticPage.SHIPPING_INSTRUCTIONS]: 'Sample shipping',
    [StaticPage.TERMS_SALES]: 'Terms and conditions',
    [StaticPage.BLOG]: 'Blog',
    [StaticPage.TEAM]: 'Team',
    [StaticPage.PORTAL_OFFERS_ID]: 'Offers',
    cart: {
      ariaLabel: 'Analysis order',
      empty:
        'Analysis order is empty. Please add some {{link|testing services}} to the order.',
    },
    testimonials: 'Customer references',
  },
  newsletter: {
    emailPlaceholder: 'Your email',
    feedback: {
      generalSubscribeError:
        'Error subscribing to the newsletter. Please try again in a bit.',
    },
    submitButton: 'Join',
    subscribeCallBold: 'Keep posted.',
    subscribeCallLight: 'Join our newsletter.',
  },
  notFound: {
    errorDescription:
      'This page does not exist or it has been moved to another address. Please double check the URL.',
    instructions:
      'You can browse our testing services on the {{link|testing services page}} or by picking a category below:',
    title: 'Page not found',
  },
  partnersPage: {
    hero: {
      lead: 'Become a partner laboratory',
    },
  },
  phone: {
    numberListHeading: 'Our international service numbers',
  },
  portal: {
    collapseAll: 'Collapse all',
    completedAt: 'Completed',
    estimatedCompletion: 'Estimated completion',
    expandAll: 'Expand all',
    expert: 'Measurlabs testing expert',
    expertAccess:
      'Currently using portal in expert mode. All projects are shown.',
    files: {
      downloadError:
        'Failed to download the file. If the problem persists, please {{link|contact us}}.',
      heading: 'Files',
      noFiles: 'No files.',
      noFilesContactUs:
        'Something missing? Contact {{email|info@measurlabs.com}}.',
    },
    filters: {
      noResults: 'No projects matching your search',
      noTeam:
        'You have not been added to a team, so you can only see your own projects. Contact us if you need assistance.',
      organization: 'Organization',
      organizationTooltip:
        'Visible organization can only be changed by Measurlabs employees.',
      owner: 'Owner',
      ownerAnyone: 'Anyone',
      queryPlaceholder: 'Search project by name, note or PO...',
      selectOrganization: 'Select organization',
    },
    generalFiles: {
      heading: 'General files',
    },
    loginButton: 'Log in to the portal',
    missingProjects:
      "{{strong|Not seeing all the projects you need?}} You haven't been added to a team yet, so you are only seeing the projects where the owner is {{owner}}. To see the projects of your colleagues, please contact us at {{mailto|info@measurlabs.com}}.",
    noProjects: {
      body: 'Browse our catalog of 500+ testing services to find the right one for your needs:',
      cta: 'Browse testing services',
      footer:
        'If you think this is an error and your order history is not showing up here, please contact {{email|info@measurlabs.com}} and we’ll sort it out.',
      heading: 'It looks like you don’t have any projects yet',
    },
    offer: {
      order: 'Order',
      priceBasis: {
        batch: 'batch',
        batches: 'batches',
        day: 'day',
        device: 'device',
        devices: 'devices',
        'element-per-order': 'element (per order)',
        'element-per-sample': 'element (per sample)',
        hour: 'hour',
        measurement: 'measurement',
        measurements: 'measurements',
        'one-time-fee': 'one-time fee',
        order: 'order',
        product: 'product',
        products: 'products',
        sample: 'sample',
        samples: 'samples',
      },
      tableHeaders: {
        name: 'Name',
        quantity: 'Quantity',
        sampleRequirements: 'Sample requirements',
        total: 'Total',
        turnaround: 'Turnaround',
        unit: 'Unit',
        unitPrice: 'Unit Price',
      },
      view: 'View offer',
      weeksPlural: 'weeks',
      weeksSingular: 'week',
    },
    offersAndOrders: 'Offers and orders',
    offersHeading: 'Offers',
    orderAction: 'Order online',
    orderSubmission: {
      additionalInformation: {
        heading: 'Additional information',
      },
      billingInformation: {
        heading: 'Billing information',
        reference: 'Reference or Purchase Order number',
        referencePlaceholder: 'PO-12345',
        referenceSublabel:
          'If you have a reference or a purchase order number you’d like us to include on the invoice, please enter it here',
      },
      confirmation: {
        additionalText:
          'Make sure you include a printed cover sheet in the parcel, so we can associate your samples to the right order.',
        description:
          'You can now proceed to ship your samples. We will let you know when we have received them.',
        downloadCoverSheet: 'Download cover sheet',
        feedbackQuestion: 'How easy was it to place this order?',
        title: 'Your order has been submitted',
        viewShippingInstructions: 'View shipping instructions',
      },
      contactDetails: {
        additionalReportingEmails: 'Additional reporting emails',
        additionalReportingEmailsHelper: 'You can add multiple emails.',
        company: 'Company',
        email: 'Work email',
        name: 'Contact person name',
        phone: 'Work phone',
      },
      deleteDraftModal: {
        content: 'Are you sure you want to delete this draft order?',
        heading: 'Delete draft',
        submitText: 'Delete draft',
      },
      formActions: {
        continue: 'Next',
        deleteDraft: 'Delete draft',
        saveAsDraft: 'Save as draft',
        submit: 'Submit order',
      },
      heading: 'Create an order',
      sampleHeading: {
        material: 'Material',
        name: 'Sample name',
        note: 'Note',
        tests: 'Tests',
      },
      sampleInput: {
        addTests: 'Add tests',
        delete: 'Delete sample',
        duplicate: 'Duplicate sample',
        material: 'Sample {{no}} material (if relevant)',
        materialPlaceholder: 'Sample material',
        materialSublabel: 'Describe in as much detail as needed',
        name: 'Sample {{no}} name or ID',
        namePlaceholder: 'Sample name',
        noTestsSelectedLabel: 'No tests selected',
        note: 'Sample {{no}} additional information',
        notePlaceholder: 'Sample note',
        noteSublabel: 'E.g. desired conditions or other specific instructions',
        pasteFromExcel: 'Paste samples from Excel',
        removeSample: 'Remove sample',
        searchTests: 'Search tests',
        testsLabel: 'Requested tests for sample {{no}}',
      },
      sampleModal: {
        formLabel: 'Paste your samples from Excel here (ctrl+v)',
        formPlaceholder: 'Sample 01 | Material | Additional information',
        heading: 'Paste samples from Excel',
        instructions: {
          steps: {
            1: 'First column: sample name or ID',
            2: 'Second column: sample material (if relevant)',
            3: 'Third column: additional information',
          },
          title: 'Make sure your columns are in the right order:',
        },
        preview: {
          material: 'Material',
          moreTests: '({{count}} more rows hidden)',
          name: 'Name',
          note: 'Additional information',
          pasteAgain: 'Paste again',
          tests: 'Selected tests',
          title: 'Preview',
        },
        submitText: 'Add samples',
        testSelection: {
          description:
            'These will be chosen for all the samples, but you can edit them later.',
          title: 'Requested tests',
        },
      },
      sampleRow: {
        noTestsSelected: 'No tests selected',
      },
      serviceFee:
        'We charge a {{b|service fee}} per order, unless otherwise agreed.',
      steps: {
        confirmation: 'Confirmation',
        contactsAndBilling: 'Contacts and billing',
        review: 'Review order',
        samplesAndTests: 'Samples and tests',
      },
      toast: {
        draftSaveFailed: 'Saving draft failed!',
        draftSaved: 'Draft saved',
        draftSaving: 'Saving draft',
      },
    },
    orders: {
      continueOrder: 'Continue order',
      deleteDraft: 'Delete draft',
      draftTitle: 'Draft order',
      heading: 'Online orders',
      orderTitle: 'Order',
      samples: '{{count}} samples',
    },
    owner: 'Owner',
    projectStatus: {
      analysisInProgress: 'Analysis in progress',
      completed: 'Completed',
      draft: 'Draft - Not submitted',
      offerProvided: 'Offer provided',
      samplesReceived: 'Samples received',
      submitted: 'Submitted - waiting for samples',
      waitingForReview: 'Waiting for review',
      waitingForSamples: 'Waiting for samples',
    },
    projects: 'Projects',
    reference: 'Ref',

    project: {
      namePlaceholder: 'Project name',
      oneOtherTest: 'and 1 other test',
      otherTests: 'and {{no}} other tests',
      referencePlaceholder: 'PO/Reference',
      rename: 'Rename',
      setName: 'Name this project',
      setReference: 'Add reference',

      notes: {
        edit: 'Edit notes',
        heading: 'Notes',
        placeholder: 'Notes',
        set: 'Add notes',
        tooltip:
          'Tip: use notes to store useful information about the project for later or for search keywords.',
        visibilityDisclaimer:
          'The notes are visible to Measurlabs staff, but they will not be notified',
      },
    },
    soon: 'Soon',
  },
  pricing: {
    first: 'first',
    next: 'next',
    noVat: 'Excl. VAT',
    noVatLong: 'Prices excluding VAT.',
    pcs: 'pcs',
    perSample: 'per sample',
    pricePerSample: 'Price per sample',
    priceRange: 'Typical price range',
    quantity: 'Quantity',
    startingFrom: 'Starting from',
    totalPriceLong: 'Total price (VAT 0)',
    totalPriceShort: 'Total',
    unitPrice: 'Unit price (VAT 0)',
  },
  productDetails: {
    breadcrumbRoot: 'Testing services',
    detectionLimit: 'Detection limit',
    deviceTypes: 'Device types',
    expert: 'Method expert',
    landingPageLink: 'More information about the method',
    qualitySystemFallback: 'Measurlabs validated method',
    qualitySystemValues: {
      '9001': 'ISO 9001',
      accreditedLab: 'Accredited testing laboratory',
      accreditedMethod: 'Accredited test method',
      fda: 'FDA',
      glp: 'GLP test method',
      gmp: 'GMP test method',
      reach: 'REACH',
    } satisfies Record<QualitySystem, string>,
    qualitySystems: 'Available quality systems',
    sampleMatrices: 'Suitable sample matrices',
    sampleRequirements: 'Required sample quantity',
    standard: 'Standard',
    turnaround: 'Typical turnaround time',
    turnaroundWeeksNoEstimate: 'No estimate',
    turnaroundWeeksPlural: 'weeks after receiving the samples',
    turnaroundWeeksSingular: 'week after receiving the sample',
  },
  productFilters: {
    clear: 'Clear',
    noOptions: 'No options',
    selectedFilters: 'Filters:',
    showAll: 'Show all',
    textSearch: 'Search by text, matrix, or standard...',
    [ProductFilterType.DEVICE_TYPE]: {
      label: 'Device',
      none: 'No filterable devices',
      placeholder: 'Filter devices...',
    },
    [ProductFilterType.INDUSTRY_OR_MATERIAL]: {
      label: 'Industry or material',
      none: 'No filterable industries or materials',
      placeholder: 'Filter industries...',
    },
    [ProductFilterType.TYPE_OF_TESTING]: {
      label: 'Type of testing',
      none: 'No filterable testing types',
      placeholder: 'Filter testing types...',
    },
    [ProductFilterType.STANDARD]: {
      label: 'Standard',
      none: 'No filterable standards',
      placeholder: 'Filter standards...',
    },
  },
  productListing: {
    buyOnline: 'Order online',
    noResultsBody:
      'Note that only a fraction of our services are listed on the website. Try using less specific keywords or contact us to get access to all services.',
    noResultsCardBody:
      'Only a fraction of our services are listed on the website. Contact us to get access to all services.',
    noResultsCardHeading: 'Can’t find what you’re looking for?',
    noResultsHeading: 'No results for ”{{query}}”',
    noResultsHeadingFiltered:
      'No results for ”{{query}}” with the chosen filters',
    readMore: 'Read more',
    selfCheckoutAvailable: 'Online ordering available',
  },
  productPage: {
    otherTests: 'Other tests we offer',
    productCTABanner: {
      actionLabel: 'Request offer',
      heading: 'Price',
    },
    productConfiguration: {
      addToCart: 'Order online',
      addedToCart: {
        buyDifferentOptions: 'Buy this test with different options',
        buyOther: 'Buy other tests',
        heading: 'Test added to analysis order',
        proceed: 'Proceed to add samples',
      },
      expertReview:
        'Our experts review all orders to ensure the testing method is suitable for your needs and samples.',
      heading: 'Pricing and online order',
      quantityDiscount: 'Large batches of samples are eligible for discounts.',
      samplesNote: 'Samples are entered during checkout.',
      serviceFee: 'We also charge a {{b|service fee}} per order.',
      slugLink: {
        [LinkParameterSlug.DANGEROUS_GOODS]: {
          label: 'Read more',
          url: '/shipping/#dangerous-goods',
        },
      },
    },
  },
  productPlaceholder: {
    body: "Contact us! We're able to offer a wide range of custom testing services.",
    heading: 'Not quite what you were looking for?',
  },
  richText: {
    showLess: 'Show less',
    showMore: 'Show more',
  },
  sampleConfiguration: {
    addSample: 'Add sample',
    heading: 'Sample {{no}}',
    material: 'Sample {{no}} material (matrix)',
    materialPlaceholder: 'Sample material',
    name: 'Sample {{no}} name',
    namePlaceholder: 'Sample name',
    note: 'Sample {{no}} note',
    notePlaceholder: 'Sample note',
    removeSample: 'Remove the sample',
  },
  search: {
    alsoOtherMethods:
      'We are able to solve many kinds of testing needs even if you cannot find what you are looking for on our website. {{link|Contact us!}}',
    groupHeading: {
      blogPost: 'Blog posts',
      method: 'Methods',
      product: 'Testing services',
      solution: 'Solutions',
    },
    inContextHelp: 'E.g. SEM, osmolarity, ICP, ISO 1182...',
    measurement: 'Test',
    noResults: 'No search results.',
    placeholder: 'Search tests by name or standard...',
  },
  sections: {
    home: {
      founded:
        'A team of specialists ready to help. With us you always get personal service from method experts.',
    },
  },
  shareButtons: {
    ariaLabel: {
      Facebook: 'Share on Facebook',
      LinkedIn: 'Share on LinkedIn',
      X: 'Share on X',
    },
  },
  shippingInstructions: {
    dangerousGoodsAccepted: 'Dangerous goods accepted',
    dangerousGoodsNotAccepted: 'Dangerous goods {{b|not accepted}}',

    deliveryAddress: {
      EUInternational: 'EU and international customers',
      UK: 'UK customers (except dangerous goods)',
    },
    recipient: 'Recipient',
    step1: {
      instructions: {
        avoidBranded: {
          description:
            'This allows us to reuse packaging material, reducing waste.',
          title: 'Avoid branded packaging material',
        },
        enoughMaterial: {
          description:
            'You can check the required amounts from our offer. If you are concerned you don’t have enough sample material, contact us.',
          title: 'Include enough sample material for testing',
        },
        includeCoverSheet: {
          description:
            'Without it we won’t know which order your samples are related to.',
          title:
            'Include a printout of your cover sheet as instructed in the next section',
        },
        includeSDS: {
          description:
            'The SDS is necessary to protect the health and safety of our staff.',
          title:
            'Include the Safety Data Sheet (SDS) in the parcel if shipping hazardous materials',
        },
        labelSamples: {
          description: 'Prefer printed labels instead of handwritten ones.',
          title: 'Label each sample clearly with its unique name',
        },
        packSecurely: {
          description:
            'For more information, see these {{guideLinesLink|customer packaging guidelines}}',
          title:
            'Pack the samples securely so they don’t break or leak during transport',
        },
      },
      title: 'Pack your samples carefully',
    },
    step2: {
      description:
        'Include a printed copy {{b|of one of the following}} in the parcel:',
      listItems:
        '{{li|Order cover sheet (for orders made from our customer portal)}}{{li|Order confirmation email (for orders made through our webshop)}}{{li|Manually filled sample submission form (other orders)}}',
      title: 'Print your order information and include it in the parcel',
      warning:
        'Without this printout we will not be able to identify what analyses your samples are for, which will cause delays.',
    },
    step3: {
      dangeroudGoodsDisclaimer:
        'Shipments containing dangerous goods must always be shipped to our Helsinki address.',
      description:
        'Your courier service may ask you to create a {{b|commercial invoice}}. If so, please follow the below instructions carefully to avoid unnecessary delays and fees.',
      hideCommercialInvoice: 'Hide commercial invoice instructions',
      showCommercialInvoice: 'Show commercial invoice instructions',

      recommendCourier:
        'We recommend using a courier service instead of regular mail. Ship your samples to the correct address:',
      title: 'Ship your samples',
      warningDescription:
        'See e.g. {{iataLink|IATA}} (air), {{adrLink|ADR}} (road), {{imdgLink|IMDG}} (sea). Section 14 of the Safety Data Sheet (SDS) contains information about the transport requirements.',
      warningTitle:
        'Always follow rules and regulations when shipping hazardous materials',
    },
    step4: {
      boxFooter:
        'The commercial invoice can be done electronically by the courier or using {{commercialInvoiceLink|this commercial invoice template (PDF)}}',
      boxTitle:
        'A commercial invoice is required for customs clearance for shipments to Finland coming from outside the European Union or European Economic Area. The commercial invoice should contain the following information to avoid delays and unnecessary taxes and fees:',
      bullet1: {
        description:
          'Please follow the description with “...samples for destructive testing”.',
        title: 'An accurate goods description',
      },
      bullet2: {
        description:
          'Please state the value of the goods as 1 euro / dollar / pound only.',
        title: 'The value of the goods as 1 €/£/$',
      },
      bullet3: {
        description:
          'Minimum of 6 digits. You can search for codes on the {{WCOLink|WCO website}}. Customers from the United States may need to use {{HTSLink|HTS codes}} instead.',
        title: 'A Harmonized System code (HS code) identifying the goods',
      },
      bullet4: {
        description: 'For the purpose of the shipment, please state “samples”.',
        title: 'The purpose of the shipment: samples',
      },
      bullet5: {
        description: 'Measur Oy (Measurlabs) EORI / VAT number: FI2820461-1',
        title:
          'The receiver’s Economic Operator Registration and Identification (EORI) or VAT number',
      },
      description:
        'If you are shipping samples {{b|to Helsinki from outside the European Union or European Economic Area}}, a commercial invoice is required for customs clearance. Without a commercial invoice, your samples will be held up in customs. For shipments within the EU/EEA, a commercial invoice is not needed.',
      description2:
        'If your courier service created a commercial invoice during shipping, you don’t need to do anything. If they did not, you will need to create one using {{commercialInvoiceLink|this commercial invoice template}} and and send it to {{packagesMail|packages@measurlabs.com}}',
      footer:
        'Please follow the instructions below carefully to avoid unnecessary delays and fees:',
      title: 'Send us your commercial invoice (if needed)',
    },
    title: 'Sample shipping instructions',
  },
  teamMemberPage: {
    blogCta: 'Check it out',
    blogFooter:
      'Read articles by our experts and guest authors on the practical implications of regulations, scientific advancements, and the ideal uses of different analysis methods.',
    blogHeading: '{{name}}’s latest articles in our blog',
    moreAbout: 'More about {{name}}',
    productFooter: 'Measurlabs offers 2000+ tests from 900+ laboratories.',
    productHeading: '{{name}}’s top testing services',
  },
  termsAndConditions: {
    heading: "Measurlabs' terms and conditions",
  },
  typeOfTesting: {
    'air-quality': 'Air quality and gases',
    biology: 'Biological testing',
    compositional: 'Compositional analysis',
    electrical: 'Electrical properties',
    elemental: 'Elements and ions',
    fire: 'Fire testing',
    mechanical: 'Mechanical testing',
    microbiology: 'Microbiological analysis',
    microscopy: 'Microscopy',
    particle: 'Particle testing',
    properties: 'Physical and chemical properties',
    regulatory: 'Regulatory tests',
    rheology: 'Rheology',
    'std-product': 'Standardized product tests',
    surface: 'Surface testing',
    thermal: 'Thermal testing',
  } satisfies Record<TypeOfTesting, string>,
  valuePropositions: {
    accuracyGuarantee: 'Result accuracy guarantee',
    competitivePrices: 'Competitive prices',
    fastTurnaround: 'Fast turnaround times',
    personalService: 'Personal service from method experts',
  },
}
