import {
  LinkParameterSlug,
  IndustryOrMaterial,
  QualitySystem,
  TypeOfTesting,
} from '../../logic/catalog'
import { territoriesFi } from '../../logic/country'
import { ProductFilterType } from '../../logic/shared-types'
import { StaticPage } from '../../logic/staticPage'

export default {
  aboutUs: {
    partnerQuestion: 'Haluatko kumppanilaboratorioksi?',
  },
  address: {
    openingHours: {
      helsinki: 'ma–pe klo 7.45–15.45',
      sampleReception: 'Näytteiden vastaanotto',
    },
  },
  aria: {
    linkedInPage: 'LinkedIn-sivu',
    search: 'Haku',
  },
  common: {
    askForOffer: 'Pyydä tarjous',
    askMore: 'Kysy lisää',
    cancel: 'Peruuta',
    contactUs: 'Ota yhteyttä',
    createdAt: 'Luotu',
    edit: 'Muokkaa',
    formSubmitError:
      'Lomakkeen lähetys epäonnistui. Ole hyvä ja ota meihin yhteyttä jollain toisella tavalla.',
    getStarted: 'Ota yhteyttä',
    goToBlog: 'Lue blogiamme',
    goToShop: 'Katso palvelumme',
    goToSolutions: 'Katso ratkaisumme',
    homePage: 'Etusivu',
    inactiveEmployee: 'ei aktiivinen',
    keepReading: 'Jatka lukemista',
    optional: '(valinnainen)',
    orContact: 'tai ota yhteyttä',
    partner: 'Liity kumppaniksi',
    readMore: 'Lue lisää',
    sampleSingular: 'näyte',
    samplesplural: 'näytettä',
    save: 'Tallenna',
  },

  banner: {
    one: {
      primary: 'Jätä testisi meille',
      secondary: 'ja keskity siihen, mitä teet parhaiten.',
    },
    two: {
      primary: 'Kumppanisi kaikessa',
      secondary: 'mittaamisessa ja testauksessa.',
    },
  },
  blogLeadForm: {
    ctaButton: 'Lähetä',
    emailLabel: 'Työsähköposti',
    error:
      'Lähetys epäonnistui. Ole hyvä ja ota meihin yhteyttä jollain toisella tavalla.',
    success: 'Kiitos tilauksesta! Käy katsomassa sähköpostisi',
  },
  blogPostPage: {
    hero: {
      published: 'Julkaistu',
      updated: 'Päivitetty',
    },
    tableOfContents: 'Sisällysluettelo',
  },
  campaign: {
    whyUs1: {
      long: 'saat kaikki testisi samasta paikasta.',
      short: 'Laajin valikoima testausmenetelmiä',
    },
    whyUs2: {
      long: 'olemme valinneet jokaista testiä varten parhaan laboratorion kullekin menetelmälle. Näin saat kaikista tarkimmat tulokset ja parhaat hinnat.',
      short: 'Parhaat laboratoriot',
    },
    whyUs3: {
      long: 'kysy apua menetelmäasiantuntijoilta, jos olet epävarma standardeista, testaustarpeistasi tai näytteiden soveltuvuudesta.',
      short: 'Henkilökohtainen palvelu',
    },
    whyUsTitle: 'Miksi valita Measurlabs?',
  },
  careers: {
    copyMessage: 'Kopioi linkki ilmoitukseen',
    copySucces: 'Linkki kopioitu leikepöydälle',
  },
  cart: {
    additionalDetails: {
      heading: 'Lisätiedot',
      one: 'Mihin tarkoitukseen käytät testauksen tuloksia? Mitä muuta meidän tulisi tietää?',
      two: 'Mitä paremmin ymmärrämme tarpeesi, sitä parempaa testauspalvelua pystymme tarjoamaan.',
    },
    additionalTests:
      'Voit lisätä vielä muita testauspalveluita samaan tilaukseen:',
    additionalTestsBtn: '+ Lisää testauspalveluita',
    nextStep: 'Jatka laskutustietoihin',
    removeItem: 'Poista tuote analyysitilaukselta',
    samplesHeading: 'Näytteet ({{count}})',
    serviceFee: 'Palvelumaksu',
    serviceFeeDescription:
      'Hintaan sisältyy projektinhallinta ja raportointi sekä näytteiden käsittely, säilytys ja hävittäminen. Palvelumaksu veloitetaan kerran per tilaus (kerralla analysoitu näytesarja).',

    tests: {
      heading: 'Testit ({{count}})',
      turnaroundHeading: 'Arvioitu läpimenoaika',
      turnaroundWeeksPlural: '{{value}} viikkoa',
      turnaroundWeeksSingular: '{{value}} viikko',
    },

    sampleConfiguration: {
      hideRequirements: 'Piilota näytevaatimukset',
      showRequirements: 'Avaa näytevaatimukset',
    },

    billing: {
      address: 'Laskutusosoite',
      addressPlaceholder: 'Teollisuuskatu 33\n00510 Helsinki',
      billingEmail: 'Sähköpostiosoite PDF-laskulle',
      billingHeading: 'Laskutustiedot',
      contactDetailsHeading: 'Asiakastiedot',
      eInvoiceOperator: 'Verkkolaskuoperaattori',
      eInvoiceRoutingNumber: 'Verkkolaskutusosoite (EDI/GLN/OVT)',
      nextStep: 'Jatka yhteenvetoon',
      paymentMethod: {
        bankTransfer: 'PDF-lasku (tilisiirto)',
        creditCard: 'Luottokortti (linkki maksuun toimitetaan sähköpostiin)',
        eInvoice: 'E-lasku (ainoastaan suomalaiset asiakkaat)',
        label: 'Maksutapa',
        notSelected: 'Lasku tai luottokortti',
      },
      reference: 'Viitteenne',
      referenceHelper:
        'Jos sinulla on ostotilaus- tai viitetieto, jonka haluat mukaan laskulle, voit syöttää sen tähän.',
      vatNumber: 'Y-tunnus',
      vatNumberPlaceholder: '2820461-1',
    },
    confirmation: {
      confirmationDetails:
        'Olemme vastaanottaneet tilauksesi ja vahvistus tästä on lähetetty sähköpostiosoitteeseesi {{email}}.',
      confirmationEstimation:
        '{{b|Käymme läpi tilauksesi noin yhden arkipäivän kuluessa.}} Voit nyt alkaa valmistella näytteitä toimitusta varten.',
      serviceCoverage:
        'Measurlabs valitsee sinulle sopivimmat testit kattavan kumppanilaboratorioverkostomme joukosta. Toimimme Suomessa ja palvelemme asiakkaitamme maailmanlaajuisesti.',
      shippingInstructionsLink:
        'Täydet ohjeet näytteiden lähettämiseen löydät {{link|lähetysohjesivultamme}}.',
    },
    copyCart: {
      copiedMessage: 'Linkki kopioitu leikepöydälle',
      modal: {
        content:
          'Olet avannut linkin esitäytettyyn tilaukseen vaikka nykyinen tilaus on vielä kesken. Haluatko korvata nykyisen tilauksen?',
        heading: 'Esitäytetty tilaus',
        submitLabel: 'Korvaa nykyinen tilaus',
      },
      prompt: 'Kopioi tilaus',
    },
    navigation: {
      billingInformation: 'Laskutustiedot',
      cart: 'Testit ja näytteet',
      review: 'Tilauksen yhteenveto',
    },
    orderSummary: {
      paymentMethodLabel: 'Maksutapa:',
      priceLabel: 'Yhteensä (ALV 0)',
      productCountPlural: 'testiä',
      productCountSingular: 'testi',
      sampleCountPlural: 'näytettä',
      sampleCountSingular: 'näyte',
      sampleDeliveryLabel: 'Näytteiden toimitus:',
      serviceFee: 'Sisältää {{fee}} palvelumaksun',
      tatLabel: 'Arvioitu läpimenoaika:',
    },
    review: {
      acceptMarketing:
        'Haluan kuulla erikoistarjouksista ja palvelupäivityksistä',
      acceptTerms: 'Hyväksyn {{link|Measurlabsin myynti- ja toimitusehdot}}',
      additionalDetails: {
        heading: 'Lisätiedot',
        notProvided: 'Ei lisätietoja.',
      },
      errors: {
        apiError:
          'Tilauksen lähetys epäonnistui. Kokeile myöhemmin uudelleen tai {{a|ota yhteyttä!}}',
        termsNotAccepted:
          'Sinun on hyväksyttävä toimitusehdot ennen tilauksen tekemistä.',
      },
      nextSteps: {
        content: `{{li|Saat sähköpostiisi muutaman minuutin kuluessa automaattisen tilausvahvistuksen, jossa on ohjeet näytteiden toimittamiseen.}}
          {{li|Asiantuntijamme tarkastaa tilauksen viimeistään seuraavan arkipäivän aikana ja varmistaa että valitsemasi analyysi varmasti vastaa tarpeisiisi ja sopii näytteillesi. Saat tästä tiedon sähköpostilla.}}
          {{li|Toimitat näytteet saamiesi ohjeiden mukaisesti. Ilmoitamme sähköpostitse kun olemme vastaanottaneet näytteet.}}
          {{li|Uusilta asiakkailta edellytämme ennakkomaksua. Lähetämme sinulle laskun, kun olemme vastaanottaneet ja tarkastaneet näytteet. Palaavat asiakkaat saavat laskun tulosten lähettämisen jälkeen.}}
          {{li|Analysoimme näytteesi, ja saat tulokset sähköpostiisi PDF-tiedostona.}}
        `,
        contentCC: `{{li|Saat sähköpostiisi muutaman minuutin kuluessa automaattisen tilausvahvistuksen, jossa on ohjeet näytteiden toimittamiseen.}}
          {{li|Asiantuntijamme tarkastaa tilauksen viimeistään seuraavan arkipäivän aikana ja varmistaa että valitsemasi analyysi varmasti vastaa tarpeisiisi ja sopii näytteillesi. Saat tästä tiedon sähköpostilla.}}
          {{li|Toimitat näytteet saamiesi ohjeiden mukaisesti. Ilmoitamme sähköpostitse kun olemme vastaanottaneet näytteet.}}
          {{li|Uusilta asiakkailta edellytämme ennakkomaksua. Lähetämme sinulle tiedot luottokorttimaksua varten, kun olemme vastaanottaneet ja tarkastaneet näytteet. Palaavat asiakkaat saavat maksutiedot tulosten lähettämisen jälkeen.}}
          {{li|Analysoimme näytteesi, ja saat tulokset sähköpostiisi PDF-tiedostona.}}
        `,
        heading: 'Mitä tapahtuu kun lähetät tilauksen',
      },
      pricingSummaryHeading: 'Hinnat',
      sampleRequirements: {
        heading: 'Vaatimukset näytteille',
        matricesHeading: 'Soveltuvat näytematriisit',
        minimumSampleAmountHeading: 'Näytteiden vähimmäismäärä',
      },
      sampleSummary: {
        fallbackName: 'Nimetön näyte',
        heading: 'Yhteenveto näytteistä',
        noMaterialInformation: 'Materiaalitietoja ei annettu',
        sampleCountPlural: '{{numSamples}} näytettä',
        sampleCountSingular: '{{numSamples}} näyte',
        sampleNameHeading: 'Nimi',
        selected: 'Valittu',
      },
      submitOrder: 'Lähetä tilaus',
    },
  },
  categoryListing: {
    noResultsContact:
      'Etkö löydä etsimääsi? {{link|Ota yhteyttä}} niin autamme!',
  },
  contact: {
    logistics: {
      content:
        'Ole yhteydessä sähköpostitse: {{email|info@measurlabs.com}} tai soittamalla numeroon: {{phone|+358 50 336 6128}}',
      title: 'Logistiikka, laskutus ja muut kyselyt',
    },
    officeNames: {
      FI: 'Suomen pääkonttori',
      GB: 'Yhdistynyt kuningaskunta',
    },
    offices: 'Toimistomme',
    salesAnchor: 'myynti',
  },
  contactBanner: {
    businessHours: 'Palvelemme: ma–pe klo 9–17',
    heading: 'Heräsikö kysymyksiä? Autamme mielellämme.',
    sales: 'Ole yhteydessä myyjiimme',
  },
  contactForm: {
    defaultInstructions: {
      1: 'Näytteiden lukumäärä ja näytemateriaalin tarkka kuvaus',
      2: 'Testaustarpeen toistuvuus: kuinka usein tarvitsette vastaavia testejä?',
      intro:
        'Voimme laatia tarjouksen nopeammin, kun sisällytät viestiin seuraavat tiedot:',
    },
    heading: {
      partner: 'Liity kumppanilaboratorioksi',
      primary: 'Ota yhteyttä',
      secondary: 'Tarjouspyyntö',
    },
    label: {
      company: 'Yritys',
      companySublabel: 'Palvelemme toistaiseksi vain yrityksiä ja yhteisöjä.',
      email: 'Työsähköpostiosoite',
      message: 'Kuvaile testaustarpeitanne mahdollisimman yksityiskohtaisesti',
      name: 'Nimi',
      phone: 'Työpuhelin',
      phoneSublabel: 'Soitamme tähän numeroon jos tarvitsemme lisätietoja.',
      services: 'Millaisia mittauspalveluita tarjoatte?',
    },
    lead: {
      partner:
        'Täytä lomake ja otamme sinuun yhteyttä. Voit myös lähettää sähköpostia {{email}} tai soittaa meille {{phone}}.',
      primary:
        'Ota yhteyttä alla olevalla lomakkeella, niin saat tarjouksen testauspalveluista {{b|yhdessä arkipäivässä}}.',
    },
    placeholder: {
      phone: '+358 40 123 4567',
    },
    questions:
      'Onko sinulla kysymyksiä tai tarvitsetko apua? Lähetä meille sähköpostia {{email|info@measurlabs.com}} tai soita {{link|myyjillemme}}.',
    send: 'Lähetä',
    sendErr: 'Viestiäsi ei lähetetty. Yritä uudelleen',
    success: {
      content:
        'Olemme vastaanottaneet viestisi. Asiantuntijamme ovat sinuun yhteydessä viimeistään seuraavana arkipäivänä.',
      heading: 'Kiitos!',
    },
  },
  country: {
    ...territoriesFi,
    other: 'Muut maat',
  },
  expert: {
    defaultHeading: 'Kysyttävää?',
    defaultIntro: '{{name}} vastaa mielellään kysymyksiinne.',
  },
  footer: {
    addressCountryFinland: '',
    businessHours: {
      fi: 'ma–pe klo 9–17',
      gb: 'ma–pe klo 9–17',
    },
    headings: {
      address: 'Käyntiosoite',
      businessHours: 'Aukioloajat',
      contact: 'Yhteystiedot',
      courierContact: 'Kuriirilähetysten vastaanottaja',
      nav: 'Valikko',
      portal: 'Asiakasportaali',
    },
    portalIntro:
      'Asiakasportaalistamme löydät kaikki projektisi, tuloksesi ja paljon muuta.',
    socialLinkAriaLabel: {
      Facebook: 'Measurlabsin Facebook-sivu',
      Instagram: 'Measurlabsin Instagram-sivu',
      LinkedIn: 'Measurlabsin LinkedIn-sivu',
      X: 'Measurlabsin X-sivu',
    },
  },
  home: {
    expertCta: 'Tutustu asiantuntijoihimme',
    hero: {
      clientCount: '...ja yli 700 muuta tyytyväistä asiakasta',
    },
  },
  industryOrMaterial: {
    biorefinery: 'Biojalostamot',
    'building-materials': 'Rakennusmateriaalit',
    chemical: 'Kemikaalit',
    'child-care': 'Lastentuotteet',
    cosmetic: 'Kosmetiikka',
    'energy-and-fuel': 'Energia ja polttoaineet',
    environmental: 'Ympäristötestaus',
    'food-and-feed': 'Ruoka, rehu ja lisäravinteet',
    'food-contact': 'Ruokakontaktimateriaalit',
    marine: 'Merenkulku',
    medical: 'Lääkinnälliset laitteet',
    metal: 'Metallit ja kaivosteollisuus',
    packaging: 'Pakkausmateriaalit',
    paper: 'Paperi ja kartonki',
    pharma: 'Lääketeollisuus',
    polymer: 'Polymeerit ja muovit',
    'safety-equipment': 'Suojavälineet',
    semiconductors: 'Puolijohteet',
    textile: 'Tekstiilit ja kalusteet',
    waste: 'Jätteet',
  } satisfies Record<IndustryOrMaterial, string>,
  localeSelector: {
    currency: 'Valuutta',
    language: 'Kieli',
  },
  metadata: {
    '404': {
      description:
        'Etsimääsi sivua ei löydy sivustoltamme. Se on saatettu poistaa tai siirtää toiseen osoitteeseen.',
      title: 'Sivua ei löydy',
    },
    [StaticPage.CART]: {
      title: 'Testit ja näytteet',
    },
    [StaticPage.CART_BILLING]: {
      title: 'Laskutustiedot',
    },
    [StaticPage.CART_REVIEW]: {
      title: 'Tilauksen yhteenveto',
    },
    [StaticPage.PORTAL_OFFERS_ID]: {
      title: 'Tarjous',
    },
    [StaticPage.ORDER_CONFIRMATION]: {
      title: 'Kiitos tilauksestasi!',
    },
    [StaticPage.PORTAL_ORDER_SUBMISSION_REVIEW]: {
      title: 'Tilauksen esikatselu',
    },
    [StaticPage.PORTAL_ORDER_SUBMISSION_CONTACT_DETAILS]: {
      title: 'Tee tilaus - Yhteystiedot',
    },
    [StaticPage.PORTAL_ORDER_SUBMISSION_SAMPLES]: {
      title: 'Tee tilaus - Testit ja näytteet',
    },
    [StaticPage.PRIVACY]: {
      description: 'Measurlabsin tietosuojaseloste',
      title: 'Tietosuoja',
    },
    [StaticPage.SHIPPING_INSTRUCTIONS]: {
      description: 'Näytteiden lähetysohjeet',
      title: 'Näytteiden lähetys',
    },
    [StaticPage.TERMS_SALES]: {
      description: 'Measurlabsin myynti- ja toimitusehdot',
      title: 'Myynti- ja toimitusehdot',
    },
    [StaticPage.TERMS_ONLINE]: {
      description: 'Measurlabsin käyttöehdot',
      title: 'Käyttöehdot',
    },
    [StaticPage.PRODUCTS]: {
      description: 'Measurlabsin tarjoamat laboratoriotestit.',
      title: 'Testauspalvelut',
    },
    [StaticPage.PROJECTS]: {
      title: 'Projektit',
    },
    offersAndOrders: {
      title: 'Tarjoukset ja tilaukset',
    },
  },
  methodPage: {
    headings: {
      faq: 'Usein kysytyt kysymykset',
      sampleMatrices: 'Soveltuvat näytematriisit',
    },
  },
  nav: {
    [StaticPage.INDEX]: 'Etusivu',
    [StaticPage.METHODS]: 'Menetelmät',
    [StaticPage.PARTNERS]: 'Laboratorioille',
    [StaticPage.SOLUTIONS]: 'Ratkaisut',
    [StaticPage.PRODUCTS]: 'Testauspalvelut',
    [StaticPage.CONTACT]: 'Ota yhteyttä',
    [StaticPage.ABOUT]: 'Meistä',
    [StaticPage.CAREERS]: 'Työpaikat',
    [StaticPage.PRIVACY]: 'Tietosuoja',
    [StaticPage.SHIPPING_INSTRUCTIONS]: 'Näytteiden lähetys',
    [StaticPage.TERMS_SALES]: 'Toimitusehdot',
    [StaticPage.BLOG]: 'Blogi',
    [StaticPage.TEAM]: 'Tiimi',
    [StaticPage.PORTAL_OFFERS_ID]: 'Tarjoukset',
    cart: {
      ariaLabel: 'Analyysitilaus',
      empty:
        'Analyysitilauksesi on tyhjä. Ole hyvä ja lisää {{link|testejä}} tilaukseen.',
    },
    testimonials: 'Asiakastarinat',
  },
  newsletter: {
    emailPlaceholder: 'Sähköposti',
    feedback: {
      generalSubscribeError:
        'Virhe uutiskirheen tilauksessa. Ole hyvä ja yritä hetken kuluttua uudelleen.',
    },
    submitButton: 'Liity',
    subscribeCallBold: 'Pysy ajan tasalla.',
    subscribeCallLight: 'Tilaa uutiskirje.',
  },
  notFound: {
    errorDescription:
      'Tätä sivua ei ole olemassa tai se on voitu siirtää toiseen osoitteeseen. Ole hyvä ja tarkasta sivun URL.',
    instructions:
      'Voit selata palveluvalikoimaamme {{link|Testauspalvelut-sivulla}} tai valitsemalla kategorian alta:',
    title: 'Sivua ei löydy',
  },
  partnersPage: {
    hero: {
      lead: 'Ryhdy kumppanilaboratorioksi',
    },
  },
  phone: {
    numberListHeading: 'Kansainväliset palvelunumeromme',
  },
  portal: {
    collapseAll: 'Sulje kaikki',
    completedAt: 'Valmistui',
    estimatedCompletion: 'Arvioitu valmistuminen',
    expandAll: 'Avaa kaikki',
    expert: 'Measurlabs-asiantuntija',
    expertAccess:
      'Käytössäsi on asiantuntijan näkymä. Kaikki projektit ovat näkyvissä.',
    files: {
      downloadError:
        'Tiedoston lataaminen epäonnistui. Jos et saa tiedostoa ladattua, niin {{link|ota meihin yhteyttä}}.',
      heading: 'Tiedostot',
      noFiles: 'Ei tiedostoja.',
      noFilesContactUs:
        'Puuttuuko jotain? Ota yhteyttä {{email|info@measurlabs.com}}.',
    },
    filters: {
      noResults: 'Projekteja ei löytynyt haulla',
      noTeam:
        'Sinua ei ole liitetty tiimiin, joten näet vain omat projektsi. Ota yhteyttä, jos tarvitset apua.',
      organization: 'Organisaatio',
      organizationTooltip:
        'Organisaatiota voivat vaihtaa vain Measurlabsin työntekijät.',
      owner: 'Omistaja',
      ownerAnyone: 'Kuka tahansa',
      queryPlaceholder:
        'Hae projekteja nimellä, muistiinpanolla tai viitteellä',
      selectOrganization: 'Valitse organisaatio',
    },
    generalFiles: {
      heading: 'Yleiset tiedostot',
    },
    loginButton: 'Kirjaudu portaaliin',
    missingProjects:
      'Etkö löydä etsimääsi? Sinua ei ole liitetty tiimiin, joten näet vain omat projektisi. {{mailto|Ota yhteyttä}} nähdäksesi muut tiimisi projektit!',
    noProjects: {
      body: 'Selaa 500+ testauspalvelun valikoimaamme ja löydä sopiva testi tarpeisiisi:',
      cta: 'Selaa testauspalveluita',
      footer:
        'Jos tilaushistoriasi puuttuu palvelusta ja uskot tämän olevan virhe, laita viesti osoitteeseen {{email|info@measurlabs.com}} ja hoidamme asian kuntoon.',
      heading: 'Vaikuttaa siltä, ettei sinulla ole vielä projekteja',
    },
    offer: {
      order: 'Tilaa',
      priceBasis: {
        batch: 'erä',
        batches: 'erät',
        day: 'päivä',
        device: 'laite',
        devices: 'laitteet',
        'element-per-order': 'alkuaine (per tilaus)',
        'element-per-sample': 'alkuaine (per näyte)',
        hour: 'tunti',
        measurement: 'mittaus',
        measurements: 'mittaukset',
        'one-time-fee': 'kertamaksu',
        order: 'tilaus',
        product: 'tuote',
        products: 'tuotteet',
        sample: 'näyte',
        samples: 'näytteet',
      },
      tableHeaders: {
        name: 'Nimi',
        quantity: 'Määrä',
        sampleRequirements: 'Näytevaatimukset',
        total: 'Yhteensä',
        turnaround: 'Toimitusaika',
        unit: 'Yksikkö',
        unitPrice: 'Yksikköhinta',
      },
      view: 'Näytä tarjous',
      weeksPlural: 'viikkoa',
      weeksSingular: 'viikko',
    },
    offersAndOrders: 'Tarjoukset ja tilaukset',
    offersHeading: 'Tarjoukset',
    orderAction: 'Tilaa verkossa',
    orderSubmission: {
      additionalInformation: {
        heading: 'Lisätiedot',
      },
      billingInformation: {
        heading: 'Laskutustiedot',
        reference: 'Viitteenne',
        referencePlaceholder: '12345',
        referenceSublabel:
          'Jos sinulla on ostotilaus- tai viitetieto, jonka haluat mukaan laskulle, voit syöttää sen tähän.',
      },
      confirmation: {
        additionalText:
          'Muista pakata tulostettu näytelähete lähetykseen, jotta voimme yhdistää näytteesi oikeaan tilaukseen.',
        description:
          'Voit nyt jatkaa näytteiden lähettämiseen. Ilmoitamme sinulle, kun olemme vastaanottaneet ne.',
        downloadCoverSheet: 'Lataa näytelähete',
        feedbackQuestion: 'Kuinka helppoa tämän tilauksen tekeminen oli?',
        title: 'Tilauksesi on lähetetty',
        viewShippingInstructions: 'Näytä lähetysohjeet',
      },
      contactDetails: {
        additionalReportingEmails: 'Muut tulosten toimitusosoitteet',
        additionalReportingEmailsHelper: 'Voit lisätä useamman sähköpostin.',
        company: 'Yritys',
        email: 'Työsähköposti',
        name: 'Yhteishenkilö',
        phone: 'Työpuhelin',
      },
      deleteDraftModal: {
        content: 'Haluatko varmasti poistaa tilausluonnoksen?',
        heading: 'Poista luonnos',
        submitText: 'Poista luonnos',
      },
      formActions: {
        continue: 'Seuraava',
        deleteDraft: 'Poista luonnos',
        saveAsDraft: 'Tallenna luonnoksena',
        submit: 'Lähetä tilaus',
      },
      heading: 'Tee tilaus',
      sampleHeading: {
        material: 'Materiaali',
        name: 'Näyte',
        note: 'Lisätiedot',
        tests: 'Mittaukset',
      },
      sampleInput: {
        addTests: 'Lisää mittauksia',
        delete: 'Poista näyte',
        duplicate: 'Kopioi näyte',
        material: 'Näytteen {{no}} materiaali (valinnainen)',
        materialPlaceholder: 'Näytteen materiaali',
        materialSublabel:
          'Kuvaile näytteen materiaalia mahdollisimman tarkasti.',
        name: 'Näytteen {{no}} nimi tai tunniste',
        namePlaceholder: 'Näytteen nimi',
        noTestsSelectedLabel: 'Ei valittuja testejä näytteelle',
        note: 'Näytteen {{no}} lisätiedot',
        notePlaceholder: 'Lisätiedot',
        noteSublabel: 'Esim. mittausolosuhteet tai muut tarkentavat ohjeet',
        pasteFromExcel: 'Liitä näytteet Excelistä',
        removeSample: 'Poista näyte',
        searchTests: 'Etsi testejä',
        testsLabel: 'Mittaukset näytteelle {{no}}',
      },
      sampleModal: {
        formLabel: 'Liitä näytteesi Excelistä tähän (ctrl+v)',
        formPlaceholder: 'Näytteen nimi   materiaali  lisätiedot',
        heading: 'Liitä näytteet Excelistä',
        instructions: {
          steps: {
            1: 'Ensimmäinen sarake: näytteen nimi tai tunnus',
            2: 'Toinen sarake: näytteen materiaali (valinnainen)',
            3: 'Kolmas sarake: näytteen lisätiedot (valinnainen)',
          },
          title: 'Varmista, että sarakkeet ovat oikeassa järjestyksessä:',
        },
        preview: {
          material: 'Materiaali',
          moreTests: '({{count}} muuta riviä piilotettu)',
          name: 'Nimi',
          note: 'Lisätiedot',
          pasteAgain: 'Liitä uudelleen',
          tests: 'Valitut mittaukset',
          title: 'Esikatselu',
        },
        submitText: 'Lisää näytteet',
        testSelection: {
          description:
            'Valitse mittaukset kaikille näille näytteille. Voit myös muokata niitä myöhemmin.',
          title: 'Mittaukset',
        },
      },
      sampleRow: {
        noTestsSelected: 'Ei valittuja testejä',
      },
      serviceFee:
        'Veloitamme tilauksesta {{b|palvelumaksun}}, ellei toisin ole sovittu.',
      steps: {
        confirmation: 'Vahvistus',
        contactsAndBilling: 'Yhteys- ja laskutustiedot',
        review: 'Tilauksen esikatselu',
        samplesAndTests: 'Näytteet ja testit',
      },
      toast: {
        draftSaveFailed: 'Luonnoksen tallennus epäonnistui',
        draftSaved: 'Luonnos tallennettu',
        draftSaving: 'Tallennetaan luonnosta...',
      },
    },
    orders: {
      continueOrder: 'Jatka tilausta',
      deleteDraft: 'Poista luonnos',
      draftTitle: 'Tilausluonnos',
      heading: 'Tilaukset',
      orderTitle: 'Tilaus',
      samples: '{{count}} näytettä',
    },
    owner: 'Omistaja',
    projectStatus: {
      analysisInProgress: 'Analyysi käynnissä',
      completed: 'Valmis',
      draft: 'Luonnos - ei lähetetty',
      offerProvided: 'Tarjous annettu',
      samplesReceived: 'Näytteet vastaanotettu',
      submitted: 'Lähetetty - odotetaan näytteitä',
      waitingForReview: 'Odottaa tarkastusta',
      waitingForSamples: 'Odotetaan näytteitä',
    },
    projects: 'Projektit',
    reference: 'Viite',

    project: {
      namePlaceholder: 'Nimi',
      oneOtherTest: 'ja 1 muu testi',
      otherTests: 'ja {{no}} muuta testiä',
      referencePlaceholder: 'Viite',
      rename: 'Nimeä uudelleen',
      setName: 'Nimeä projekti',
      setReference: 'Lisää viite',

      notes: {
        edit: 'Muokkaa muistiinpanoja',
        heading: 'Muistiinpanot',
        placeholder: 'Muistiinpanot',
        set: 'Lisää muistiinpanoja',
        tooltip:
          'Vinkki: käytä muistiinpanoja tallentaaksesi tietoa projektista myöhempää käyttöä ja hakemista varten.',
        visibilityDisclaimer:
          'Muistiinpanot ovat Measurlabsin henkilökunnan nähtävissä, mutta he eivät saa ilmoituksia muutoksista.',
      },
    },
    soon: 'Pian',
  },
  pricing: {
    first: 'ensimmäinen',
    next: 'seuraavat',
    noVat: 'ALV 0',
    noVatLong: 'Hinnat ilmoitettu ilman arvonlisäveroa.',
    pcs: 'kpl',
    perSample: 'per näyte',
    pricePerSample: 'Hinta per näyte',
    priceRange: 'Tyypillinen hintataso',
    quantity: 'kpl',
    startingFrom: 'Alkaen',
    totalPriceLong: 'Kokonaishinta',
    totalPriceShort: 'Yht.',
    unitPrice: 'Yksikköhinta',
  },
  productDetails: {
    breadcrumbRoot: 'Testauspalvelut',
    detectionLimit: 'Määritysraja',
    deviceTypes: 'Mittauslaitteet',
    expert: 'Menetelmän asiantuntija',
    landingPageLink: 'Lisätietoja menetelmästä',
    qualitySystemFallback: 'Measurlabsin validoima menetelmä',
    qualitySystemValues: {
      '9001': 'ISO 9001',
      accreditedLab: 'Akkreditoitu testauslaboratorio',
      accreditedMethod: 'Akkreditoitu menetelmä',
      fda: 'FDA',
      glp: 'GLP-menetelmä',
      gmp: 'GMP-menetelmä',
      reach: 'REACH',
    } satisfies Record<QualitySystem, string>,
    qualitySystems: 'Saatavilla olevat laatujärjestelmät',
    sampleMatrices: 'Soveltuvat näytematriisit',
    sampleRequirements: 'Näytteiden minimimäärä',
    standard: 'Standardi',
    turnaround: 'Tyypillinen läpimenoaika',
    turnaroundWeeksNoEstimate: 'Ei arviota saatavilla',
    turnaroundWeeksPlural: 'viikkoa näytteiden vastaanottamisesta',
    turnaroundWeeksSingular: 'viikko näytteiden vastaanottamisesta',
  },
  productFilters: {
    clear: 'Tyhjennä',
    noOptions: 'Ei valittavia vaihtoehtoja',
    selectedFilters: 'Suodattimet:',
    showAll: 'Näytä kaikki',
    textSearch: 'Hae nimellä, näytematriisilla tai standardilla...',
    [ProductFilterType.DEVICE_TYPE]: {
      label: 'Mittauslaite',
      none: 'Ei valittavia laitteita',
      placeholder: 'Suodata laitteita...',
    },
    [ProductFilterType.INDUSTRY_OR_MATERIAL]: {
      label: 'Ala tai materiaali',
      none: 'Ei valittavia aloja tai materiaaleja',
      placeholder: 'Suodata aloja...',
    },
    [ProductFilterType.TYPE_OF_TESTING]: {
      label: 'Testaustyyppi',
      none: 'Ei valittavia testaustyyppejä',
      placeholder: 'Suodata testaustyyppejä...',
    },
    [ProductFilterType.STANDARD]: {
      label: 'Standardi',
      none: 'Ei valittavia standardeja',
      placeholder: 'Suodata standardeja...',
    },
  },
  productListing: {
    buyOnline: 'Tilaa verkossa',
    noResultsBody:
      'Huomaathan, että vain pieni osa mittauspalveluistamme on listattu netissä. Yritä käyttää eri hakusanoja tai ota meihin yhteyttä, niin saat pääsyn kaikkiin palveluihimme.',
    noResultsCardBody:
      'Vain pieni osa mittauspalveluistamme on listattu nettisivuilla. Jos et löydä etsimääsi, älä epäröi ottaa yhteyttä!',
    noResultsCardHeading: 'Etkö löydä etsimääsi?',
    noResultsHeading: 'Ei tuloksia haulle ”{{query}}”',
    noResultsHeadingFiltered:
      'Ei tuloksia haulle ”{{query}}” valituilla suodattimilla',
    readMore: 'Lue lisää',
    selfCheckoutAvailable: 'Ostettavissa verkkokaupasta',
  },
  productPage: {
    otherTests: 'Muita testejä valikoimistamme',
    productCTABanner: {
      actionLabel: 'Pyydä tarjous',
      heading: 'Hinta',
    },
    productConfiguration: {
      addToCart: 'Tilaa verkossa',
      addedToCart: {
        buyDifferentOptions: 'Osta tämä testi eri valinnoilla',
        buyOther: 'Selaa muita testauspalveluita',
        heading: 'Testi on lisätty analyysitilaukseen',
        proceed: 'Jatka syöttämään näytteet',
      },
      expertReview:
        'Asiantuntijamme tarkastavat kaikki tilaukset ja varmistavat, että testi vastaa tarpeisiisi ja sopii näytteillesi.',
      heading: 'Hinnoittelu ja tilaus',
      quantityDiscount: 'Suurille näyte-erille tarjoamme alennetun hinnan.',
      samplesNote: 'Näytteet syötetään seuraavassa vaiheessa.',
      serviceFee: 'Hintaan lisätään myös tilauskohtainen {{b|palvelumaksu}}.',
      slugLink: {
        [LinkParameterSlug.DANGEROUS_GOODS]: {
          label: 'Lue lisää',
          url: '/fi/lahetysohjeet/#dangerous-goods',
        },
      },
    },
  },
  productPlaceholder: {
    body: 'Ota meihin yhteyttä! Pystymme toimittamaan hyvin monenlaisia räätälöityjä testauspalveluita.',
    heading: 'Etkö löytänyt etsimääsi?',
  },
  richText: {
    showLess: 'Näytä vähemmän',
    showMore: 'Näytä lisää',
  },
  sampleConfiguration: {
    addSample: 'Lisää näyte',
    heading: 'Näyte {{no}}',
    material: 'Näytteen {{no}} materiaali (matriisi)',
    materialPlaceholder: 'Näytteen materiaali',
    name: 'Näytteen {{no}} nimi',
    namePlaceholder: 'Näytteen nimi',
    note: 'Näytteen {{no}} lisätiedot',
    notePlaceholder: 'Lisätiedot',
    removeSample: 'Poista näyte',
  },
  search: {
    alsoOtherMethods:
      'Pystymme luultavasti ratkaisemaan testaustarpeesi, vaikka et löytäisi hakemaasi suoraan verkkosivuiltamme. {{link|Ota yhteyttä!}}',
    groupHeading: {
      blogPost: 'Blogitekstit',
      method: 'Menetelmät',
      product: 'Testauspalvelut',
      solution: 'Ratkaisut',
    },
    inContextHelp: 'Esim. SEM, osmolaalisuus, ISO 1182...',
    measurement: 'Testi',
    noResults: 'Ei hakutuloksia.',
    placeholder: 'Hae testin nimellä tai standardilla...',
  },
  sections: {
    home: {
      founded:
        'Erikoisasiantuntijoiden tiimi valmiina auttamaan. Meillä saat aina henkilökohtaista palvelua menetelmäasiantuntijoilta.',
    },
  },
  shareButtons: {
    ariaLabel: {
      Facebook: 'Jaa Facebookissa',
      LinkedIn: 'Jaa LinkedInissä',
      X: 'Jaa X:ssä',
    },
  },
  shippingInstructions: {
    dangerousGoodsAccepted: 'Myös vaaralliset aineet',
    dangerousGoodsNotAccepted: 'Ei vaarallisia aineita',

    deliveryAddress: {
      EUInternational: 'EU- ja kansainväliset asiakkaat',
      UK: 'UK-asiakkaat (pois lukien vaaralliset aineet)',
    },
    recipient: 'Vastaanottaja',
    step1: {
      instructions: {
        avoidBranded: {
          description:
            'Tämä mahdollistaa pakkausmateriaalin uudelleenkäytön ja vähentää jätettä.',
          title: 'Vältä brändättyä pakkausmateriaalia',
        },
        enoughMaterial: {
          description:
            'Voit tarkistaa vaaditut määrät tarjouksestamme. Jos epäilet näytemateriaalisi riittävyyttä, ota meihin yhteyttä.',
          title:
            'Varmista, että sinulla on riittävästi näytemateriaalia testejä varten',
        },
        includeCoverSheet: {
          description:
            'Ilman tätä emme tiedä, mihin tilaukseen näytteesi liittyvät.',
          title:
            'Pakkaa mukaan tulostettu näytelähete seuraavan osion ohjeiden mukaisesti',
        },
        includeSDS: {
          description:
            'SDS on tarpeen henkilökuntamme terveyden ja turvallisuuden varmistamiseksi.',
          title:
            'Jos lähetät vaarallisia aineita, pakkaa mukaan käyttöturvallisuustiedote (SDS)',
        },
        labelSamples: {
          description:
            'Käytä mielummin tulostettuja kuin käsinkirjoitettuja merkintöjä',
          title: 'Merkitse näytteet selvästi ja yksilöidysti',
        },
        packSecurely: {
          description:
            'Lisätietoja saat {{guideLinesLink|asiakkaan pakkausohjeista}}',
          title:
            'Pakkaa näytteet huolellisesti, jotta ne eivät rikkoudu tai vuoda kuljetuksen aikana',
        },
      },
      title: 'Pakkaa näytteesi huolellisesti',
    },
    step2: {
      description: 'Tulosta ja pakkaa mukaan {{b|yksi seuraavista}}:',
      listItems:
        '{{li|Näytelähete (asiakasportaalistamme tehdyt tilaukset)}}{{li|Tilausvahvistussähköposti (verkkokaupastamme tehdyt tilaukset)}}{{li|Käsin täytetty näytteen lähetyslomake (muut tilaukset)}}',
      title: 'Tulosta tilauksen tiedot ja lähetä ne näytteiden mukana',
      warning:
        'Ilman tätä tulostetta emme pysty tunnistamaan, mihin analyysiin näytteesi kuuluvat, mikä aiheuttaa viivästyksiä.',
    },
    step3: {
      dangeroudGoodsDisclaimer:
        'Vaarallisia aineita sisältävät lähetykset tulee aina toimittaa Helsingin osoitteeseemme.',
      description:
        'Kuriiripalvelu saattaa pyytää sinua luomaan {{b|kauppalaskun}}. Jos näin on, noudata alla olevia ohjeita huolellisesti viivästyksien ja ylimääräisten maksujen välttämiseksi.',
      hideCommercialInvoice: 'Piilota kauppalaskuohjeet',
      showCommercialInvoice: 'Näytä kauppalaskuohjeet',

      recommendCourier:
        'Suosittelemme käyttämään kuriiripalvelua tavallisen postin sijaan. Lähetä näytteesi oikeaan osoitteeseen:',
      title: 'Lähetä näytteesi',
      warningDescription:
        'Katso lisätiedot esim. {{iataLink|IATA}} (ilma), {{adrLink|ADR}} (tie), {{imdgLink|IMDG}} (meri). Käyttöturvallisuustiedotteen (SDS) kohta 14 sisältää tietoa kuljetusvaatimuksista.',
      warningTitle:
        'Noudata aina vaarallisten aineiden kuljetusmääräyksiä ja -sääntöjä',
    },
    step4: {
      boxFooter:
        'Kauppalaskun voi tehdä sähköisesti kuriiripalvelun kautta tai käyttämällä {{commercialInvoiceLink|tätä kauppalaskupohjaa (PDF)}}',
      boxTitle:
        'Kauppalasku vaaditaan tulliselvitystä varten Suomeen tuleville lähetyksille, jotka tulevat Euroopan unionin tai Euroopan talousalueen ulkopuolelta. Kauppalaskun tulisi sisältää seuraavat tiedot viivästysten ja tarpeettomien maksujen välttämiseksi:',
      bullet1: {
        description:
          'Ole hyvä ja käytä kuvausta, jossa lukee “...näytteitä tuhoavaa testausta varten".',
        title: 'Tarkka tavaran kuvaus',
      },
      bullet2: {
        description:
          'Ilmoita tavaran arvoksi ainoastaan 1 euro / dollari / punta.',
        title: 'Tavaran arvo 1 €/£/$',
      },
      bullet3: {
        description:
          'Vähintään 6 numeroa. Voit etsiä koodeja {{WCOLink|WCO:n sivustolta}}. Yhdysvaltalaiset asiakkaat saattavat tarvita {{HTSLink|HTS-koodeja}}.',
        title: 'Tavaran nimike harmonisoidussa järjestelmässä (HS-koodi)',
      },
      bullet4: {
        description: 'Ilmoita lähetyksen tarkoitukseksi “näytteet”.',
        title: 'Lähetyksen tarkoitus: näytteitä',
      },
      bullet5: {
        description: 'Measur Oy (Measurlabs) EORI / ALV-numero: FI2820461-1',
        title:
          'Vastaanottajan talouden toimijan rekisteröinti- ja tunnistenumero (EORI) tai ALV-numero',
      },
      description:
        'Jos lähetät näytteitä {{b|Helsinkiin Euroopan unionin tai Euroopan talousalueen ulkopuolelta}}, kauppalasku vaaditaan tulliselvitystä varten. Ilman kauppalaskua näytteesi jäävät tulliin. EU/ETA-alueen sisällä kauppalaskua ei tarvita.',
      description2:
        'Jos kuriiripalvelusi teki kauppalaskun lähetyksen aikana, sinun ei tarvitse tehdä mitään. Jos ei, sinun tulee luoda kauppalasku käyttämällä {{commercialInvoiceLink|tätä kauppalaskupohjaa}} ja lähettää se osoitteeseen {{packagesMail|packages@measurlabs.com}}',
      footer:
        'Noudata alla olevia ohjeita huolellisesti välttääksesi tarpeettomat viivästykset ja maksut:',
      title: 'Lähetä meille kauppalasku (jos tarpeen)',
    },
    title: 'Näytteiden lähetysohjeet',
  },
  teamMemberPage: {
    blogCta: 'Lisää artikkeleita',
    blogFooter:
      'Lue asiantuntijoidemme ja vierailevien kirjoittajiemme artikkeleita säännösten käytännön vaikutuksista, tieteellisestä edistyksestä ja erilaisten analyysimenetelmien ihanteellisista käyttötavoista.',
    blogHeading: 'Uusimmat blogikirjoitukset',
    moreAbout: 'Lue lisää',
    productFooter:
      'Measurlabs tarjoaa 2000+ testimenetelmää 900+ eri laboratoriolta.',
    productHeading: 'Suosituimmat tuotteet',
  },

  termsAndConditions: {
    heading: 'Measurlabsin käyttöehdot',
  },
  typeOfTesting: {
    'air-quality': 'Ilmanlaatu ja kaasut',
    biology: 'Biologinen testaus',
    compositional: 'Koostumusanalyysi',
    electrical: 'Sähköiset ominaisuudet',
    elemental: 'Alkuaineet ja ionit',
    fire: 'Palotestaus',
    mechanical: 'Mekaaninen testaus',
    microbiology: 'Mikrobiologinen analyysi',
    microscopy: 'Mikroskopia',
    particle: 'Partikkelianalyysit',
    properties: 'Fysikaaliset ja kemialliset ominaisuudet',
    regulatory: 'Regulaatiotestit',
    rheology: 'Reologia',
    'std-product': 'Standardoidut tuotetestit',
    surface: 'Pintatestaus',
    thermal: 'Lämpötestaus',
  } satisfies Record<TypeOfTesting, string>,
  valuePropositions: {
    accuracyGuarantee: 'Takuu tulosten oikeellisuudesta',
    competitivePrices: 'Kilpailukykyiset hinnat',
    fastTurnaround: 'Nopeat tulokset',
    personalService: 'Henkilökohtaista apua asiantuntijoilta',
  },
}
